<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhangfei
 * @Date: 2020-11-25 11:43:18
 * @LastEditors: zhangfei
 * @LastEditTime: 2020-12-08 13:29:08
-->
<template>
  <div class="zt-page-content">
    <div class="page-header">交易集散中心</div>
    <div class="bannerList">
      <!-- <div class="bannerItem" @click="bannerIndex=-1" :class="bannerIndex === -1 ? 'bannerActive' : ''">banner</div> -->
      <div
        class="bannerItem"
        :class="bannerIndex === index ? 'bannerActive' : ''"
        v-for="(item, index) in bannerList"
        :key="item.id"
        @click="bannerClick(index,1)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="zt-block" style="height: calc(100% - 160px)">
      <div
        class="list"
        style="
          height: calc(100% - 60px);
          overflow-y: auto;
          padding: 24px 25px 30px 22px;
        "
      >
        <div class="flex flex-justify-between" v-if="bannerIndex === 0">
          <span class="bannerTitle">banner</span>
          <el-button size="small" class="addBannerBtn" @click="addBanner"
            >新增banner</el-button
          >
        </div>
        <div class="flex flex-justify-between" v-else>
          <span class="bannerTitle">{{ bannerList[bannerIndex].name }}</span>
          <el-button
            size="small"
            class="addBannerBtn"
            @click="addBannerStyle(bannerList[bannerIndex].name)"
            >新增展会</el-button
          >
        </div>
        <el-table :data="list" v-if="bannerIndex === 0" style="width: 100%">
          <el-table-column prop="name" label="banner名称">
            <template slot-scope="scope">
              <div>{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="order" label="排序">
            <template slot-scope="scope">
              <div>{{ scope.row.order }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="viewDetail1(scope.row)" type="text"
                >编辑</el-button
              >
              <el-button
                style="color: #f66f6a"
                @click="deleteDetail(scope.row)"
                type="text"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <banner
          v-else
          :list="list1"
          :bannerList="bannerList"
          @refreshList="refreshLsit"
          @editBanner="editBannerStyle"
          :bannerIndex="bannerIndex"
        ></banner>
      </div>
      <div style="padding: 12px">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="queryOpt.currentPage"
          @current-change="handleCurrentChange" 
          :page-size="queryOpt.pageSize"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      width="500px"
      :title="popTitle"
      :visible.sync="popVisible"
      :destroy-on-close="true"
    >
      <add-banner
        @refreshItems="refreshItems"
        :editObj="editObj"
        @hide="popVisible = false"
        :popVisible ="popVisible1"
        :isAdd="isAdd"
      ></add-banner>
    </el-dialog>
    <el-dialog
      width="500px"
      :title="popTitle1"
      :visible.sync="popVisible1"
      :destroy-on-close="true"
    >
      <add-banner-style
        @refreshItems="refreshLsit"
        :bannerIndex="bannerIndex"
        :editObj="editObj"
        @hide="popVisible1 = false"
        :popVisible ="popVisible1"
        :isAdd="isAdd1"
      ></add-banner-style>
    </el-dialog>
  </div>
</template>
<script>
import { bannerList, deleteBanner,wordStylelist,wordStyleDetail,joinStylelist,joinStyleDetail ,popularStyleDetail,popularStylelist,exhibitionStyleDetail,exhibitionStylelist,newStylelist,newStyleDetail} from "@/service/operation";
import addBanner from "./addBanner";
import banner from "./bannerList";
import addBannerStyle from "./addBannerStyle";
export default {
  data() {
    return {
      bannerIndex: 0,
      list: [],
      list1: [],
      bannerList: [
        {
          id: 0,
          name: "banner",
        },
        {
          id: 1,
          name: "新品上市",
        },
        {
          id: 2,
          name: "热门推荐",
        },
        {
          id: 3,
          name: "按木分类",
        },
        {
          id: 4,
          name: "招商加盟",
        },
        {
          id: 5,
          name: "展会报名",
        },
      ],
      queryOpt: {
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
      popTitle: "",
      popVisible: false,
      popTitle1: "",
      popVisible1: false,
      isAdd: true,
      isAdd1: true,
      editObj: {},
    };
  },
  components: {
    addBanner,
    banner,
    addBannerStyle,
  },
  async created() {
    this.refreshItems();
  },
  methods: {
    handleCurrentChange(val){
      this.bannerClick(this.bannerIndex,val)
    },
    addBanner() {
      if(this.list.length<5){
        this.popVisible = true;
        this.popTitle = "banner";
        this.isAdd = true;
        this.editObj = {}
      }else{
        this.$message.error('只能新增5个banner');
      }
    },
    addBannerStyle(name) {
      if(this.bannerIndex!==4){
        if(this.list.length<10){
          this.popTitle1 = name;
          this.editObj = {
            woodName: "",
            order: '',
            type: 1,
            url: "",
            commodity:[]
          }
          this.isAdd1 = true;
          this.popVisible1 = true;
        }else{
          this.$message.error(name+'最多新增10个')
        }
      }else{
          if(this.list.length<46){
          this.popTitle1 = name;
          this.editObj = {
            woodName: "",
            order: '',
            type: 1,
            url: "",
            commodity:[]
          }
          this.isAdd1 = true;
          this.popVisible1 = true;
        }else{
          this.$message.error(name+'最多新增46个')
        }
      }
     
      
    },
    refreshItems() {
      bannerList()
        .then((rst) => {
          this.total = rst.data.pageInfo.total;
          this.list = rst.data.list;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    refreshLsit(){
      this.bannerClick(this.bannerIndex,1)
    },
    bannerClick(index,currentPage) {
      this.bannerIndex = index;
      this.queryOpt.currentPage = currentPage
      if(this.bannerIndex === 3){
          this.getWoodList()
      }
      if(this.bannerIndex === 4){
          this.getJoinList()
      }
      if(this.bannerIndex === 2){
          this.getPopurList()
      }
       if(this.bannerIndex === 1){
          this.getNewList()
      }
      if(this.bannerIndex === 5){
          this.getExhibitList()
      }
    },
    getNewList(){
       newStylelist(this.queryOpt)
        .then((rst) => {
          this.total = rst.data.pageInfo.total;
          this.list1 = rst.data.list;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    getWoodList(){
      wordStylelist(this.queryOpt)
        .then((rst) => {
          this.total = rst.data.pageInfo.total;
          this.list1 = rst.data.list;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    getJoinList(){
      joinStylelist(this.queryOpt)
        .then((rst) => {
          this.total = rst.data.pageInfo.total;
          this.list1 = rst.data.list;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    getPopurList(){
      popularStylelist(this.queryOpt)
        .then((rst) => {
          this.total = rst.data.pageInfo.total;
          this.list1 = rst.data.list;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    getExhibitList(){
       exhibitionStylelist(this.queryOpt)
        .then((rst) => {
          this.total = rst.data.pageInfo.total;
          this.list1 = rst.data.list;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    viewDetail(row) {
      this.popVisible = true;
      this.popTitle = "banner";
      this.isAdd = false;
    },
    editBannerStyle(id){
      if(this.bannerIndex === 1){
        this.popTitle1 = "编辑新品上市";
        this.isAdd1 = false;
        this.editObj = {}
        this.getNewDetail(id)
      }
      if(this.bannerIndex === 3){
        this.popTitle1 = "编辑桉木分类";
        this.isAdd1 = false;
        this.editObj = {}
        this.getWoodDetail(id)
      }
      if(this.bannerIndex === 4){
        this.popTitle1 = "编辑招商加盟";
        this.isAdd1 = false;
        this.editObj = {}
        this.getJoinDetail(id)
      }
      if(this.bannerIndex === 2){
        this.popTitle1 = "编辑热门推荐";
        this.isAdd1 = false;
        this.editObj = {}
        this.getPopurDetail(id)
      }
      if(this.bannerIndex === 5){
        this.popTitle1 = "编辑展会报名";
        this.isAdd1 = false;
        this.editObj = {}
        this.getExhibitDetail(id)
      }
    },
    getNewDetail(id){
      newStyleDetail({newGoodsId:id}).then(res=>{
        // this.editObj = res.data
        this.editObj.mechanism_no = res.data.mechanism_no
        this.editObj.enterprise_name = res.data.enterprise_name
        this.editObj.enterprise_short_name = res.data.enterprise_short_name
        this.editObj.id = res.data.id
        this.editObj.order = res.data.order
        this.editObj.logo_url = res.data.logo_url
        this.editObj.commodity = []
        res.data.commodity.forEach(item=>{
          this.editObj.commodity.push({})
        })
        res.data.commodity.forEach((item,index)=>{
          this.editObj.commodity[index].commodityId = item.commodity_id
          this.editObj.commodity[index].commodityName = item.commodity_name
          this.editObj.commodity[index].companyName = item.company_name
          this.editObj.commodity[index].imgUrl = item.img_url
          this.editObj.commodity[index].id = item.id
        })
        console.log(this.editObj)
        this.popVisible1 = true;
      }).catch(err=>{
        this.$message.error(err.message);
      })

    },
    getWoodDetail(id){
      wordStyleDetail({woodStyleId:id}).then(res=>{
        // this.editObj = res.data
        this.editObj.woodName = res.data.wood_name
        this.editObj.updateTime = res.data.update_time
        this.editObj.createTime = res.data.create_time
        this.editObj.woodStyleId = res.data.id
        this.editObj.order = res.data.order
        this.editObj.commodity = []
        res.data.commodity.forEach(item=>{
          this.editObj.commodity.push({})
        })
        res.data.commodity.forEach((item,index)=>{
          this.editObj.commodity[index].commodityName = item.commodity_name
          this.editObj.commodity[index].companyName = item.company_name
          this.editObj.commodity[index].imgUrl = item.img_url
          this.editObj.commodity[index].id = item.id
          this.editObj.commodity[index].commodityId = item.commodity_id
        })
        console.log(this.editObj)
        this.popVisible1 = true;
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    getJoinDetail(id){
      joinStyleDetail({merchantJoinId:id}).then(res=>{
        this.editObj = res.data
        this.popVisible1 = true;
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    getPopurDetail(id){
      popularStyleDetail({popularId:id}).then(res=>{
        // this.editObj = res.data
        this.editObj.name = res.data.name
        this.editObj.updateTime = res.data.update_time
        this.editObj.createTime = res.data.create_time
        this.editObj.popularId = res.data.id
        this.editObj.icon_url = res.data.icon_url,
        this.editObj.img_url = res.data.img_url
        this.editObj.order = res.data.order
        this.editObj.commodity = []
        res.data.commodity.forEach(item=>{
          this.editObj.commodity.push({})
        })
        res.data.commodity.forEach((item,index)=>{
          this.editObj.commodity[index].commodityName = item.commodity_name
          this.editObj.commodity[index].companyName = item.company_name
          this.editObj.commodity[index].imgUrl = item.img_url
          this.editObj.commodity[index].id = item.id
          this.editObj.commodity[index].commodityId = item.commodity_id
        })
        console.log(this.editObj)
        this.popVisible1 = true;
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    getExhibitDetail(id){
      exhibitionStyleDetail({exhibitionId:id}).then(res=>{
        this.editObj = res.data
        this.popVisible1 = true;
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    viewDetail1(row) {
      this.popVisible = true;
      this.popTitle = "编辑banner";
      this.isAdd = false;
      this.editObj = {}
      this.editObj = Object.assign(row,{});
    },
    deleteDetail(row) {
      this.$confirm(`确认要删除吗?`, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteBanner({ bannerId: row.id })
          .then((res) => {
            this.$message.success("删除成功!");
            this.refreshItems()
          })
          .catch((err) => {});
      });
    }
  },
};
</script>
<style scoped>
.bannerTitle {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #292929;
  line-height: 22px;
}
.bannerList {
  padding: 20px 0 0 20px;
  display: flex;
}
.bannerItem {
  width: 86px;
  height: 36px;
  background: #e6e6e6;
  border-radius: 2px;
  text-align: center;
  margin-right: 20px;
  line-height: 36px;
  cursor: pointer;
  font-size: 14px;
}
.bannerActive {
  background: #5074ee;
  color: #ffffff;
}
.addBannerBtn {
  width: 98px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #5074ee;
  padding: 0px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #5074ee;
  line-height: 30px;
}
</style>
<style>
.zt-page-content .el-dialog {
  /* max-height: 940px; */
  overflow: auto;
  /* min-height: 500px; */
  position: relative;
}
</style>