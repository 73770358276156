<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhangfei
 * @Date: 2020-11-28 14:58:14
 * @LastEditors: zhangfei
 * @LastEditTime: 2020-12-03 19:50:33
-->
<template>
    <div>
        <el-table :data="list" style="width: 100%">
          <el-table-column prop="enterprise_name" v-if="bannerIndex===1" label="公司名称">
            <template slot-scope="scope">
              <div>{{ scope.row.enterprise_name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="name" v-if="bannerIndex===2" label="类目名称">
            <template slot-scope="scope">
              <div>{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="wood_name" v-if="bannerIndex===3" label="木材名称">
            <template slot-scope="scope">
              <div>{{ scope.row.wood_name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="enterprise_name" v-if="bannerIndex===4" label="招商加盟">
            <template slot-scope="scope">
              <div>{{ scope.row.enterprise_name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="name" v-if="bannerIndex===5" label="家具展会名称">
            <template slot-scope="scope">
              <div>{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="order" label="排序">
            <template slot-scope="scope">
              <div>{{ scope.row.order }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="commodity_count" v-if="bannerIndex===0||bannerIndex===1||bannerIndex===2" label="商品数">
            <template slot-scope="scope">
              <div>{{ scope.row.commodity_count }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="meter_number" label="操作">
            <template slot-scope="scope">
              <el-button @click="viewDetail(scope.row)" type="text"
                >编辑</el-button
              >
              <el-button
                style="color: #f66f6a"
                @click="deleteDetail(scope.row)"
                type="text"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table> 
        <!-- <div style="padding: 12px">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="queryOpt.currentPage"
          :page-size="queryOpt.pageSize"
          :total="total"
        ></el-pagination>
      </div> -->
    </div>
</template>
<script>
import {deleteWoodStyle,deleteJoinStyle,deleteExhibitionStyle,deletePopularStyle,deleteNewStyle} from '@/service/operation'
// import { delete } from 'vue/types/umd'
export default {
    data() {
        return {
           
        }
    },
    props:['bannerIndex','list'],
    methods:{
        viewDetail(row){
          if(this.bannerIndex === 3) {
            this.editWood(row.id)
          }
          if(this.bannerIndex === 4) {
            this.editJoin(row.id)
          }
          if(this.bannerIndex === 2){
            this.editPopular(row.id)
          }
          if(this.bannerIndex === 1){
            this.editNew(row.id)
          }
          if(this.bannerIndex === 5){
              this.editExhibt(row.id)
          }
        },
        deleteDetail(row){
            if(this.bannerIndex === 3) {
                this.deleteWood(row.id)
            }else if(this.bannerIndex === 2){
                this.deletePopular(row.id)
            }else if(this.bannerIndex === 1){
                this.deleteNew(row.id)
            }else if(this.bannerIndex === 4){
                this.deleteJoin(row.id)
            }else if(this.bannerIndex === 5){
                this.deleteExhibt(row.id)
            }
        },
        editWood(woodStyleId){
          this.$emit("editBanner",woodStyleId)
        },
        editPopular(merchantJoinId){
          this.$emit("editBanner",merchantJoinId)
        },
        editJoin(merchantJoinId){
          this.$emit("editBanner",merchantJoinId)
        },
        editExhibt(exhibitionId){
          this.$emit("editBanner",exhibitionId)
        },
        editNew(newGoodsId){
          this.$emit("editBanner",newGoodsId)
        },
        deleteWood(woodStyleId){ 
          this.$confirm(`确认要删除吗?`, "温馨提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              deleteWoodStyle({ woodStyleId: woodStyleId }).then((res) => {
                this.$message.success("删除成功!");
                this.$emit("refreshList")
              }).catch((err) => {});
          });
        },
        deletePopular(popularId){ 
          this.$confirm(`确认要删除吗?`, "温馨提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              deletePopularStyle({ popularId: popularId }).then((res) => {
                this.$message.success("删除成功!");
                this.$emit("refreshList")
              }).catch((err) => {});
          });
        },
        deleteJoin(merchantJoinId){ 
          this.$confirm(`确认要删除吗?`, "温馨提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              deleteJoinStyle({ merchantJoinId: merchantJoinId }).then((res) => {
                this.$message.success("删除成功!");
                this.$emit("refreshList")
              }).catch((err) => {});
          });
        },
        deleteExhibt(exhibitionId){ 
          this.$confirm(`确认要删除吗?`, "温馨提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              deleteExhibitionStyle({ exhibitionId: exhibitionId }).then((res) => {
                this.$message.success("删除成功!");
                this.$emit("refreshList")
              }).catch((err) => {});
          });
        },
        deleteNew(newGoodsId){ 
          this.$confirm(`确认要删除吗?`, "温馨提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              deleteNewStyle({ newGoodsId: newGoodsId }).then((res) => {
                this.$message.success("删除成功!");
                this.$emit("refreshList")
              }).catch((err) => {});
          });
        },
        
    }
}
</script>
<style scoped>

</style>