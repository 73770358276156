<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhangfei
 * @Date: 2020-11-28 13:58:07
 * @LastEditors: zhangfei
 * @LastEditTime: 2020-12-08 17:14:46
-->
<template>
  <div class="addBanner">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="企业ID"
        v-if="bannerIndex === 1 || bannerIndex === 4"
        prop="mechanism_no"
      >
        <div class="flex" style="position: relative">
          <el-input v-model="ruleForm.mechanism_no"></el-input>
          <img
            src="../../../assets/images/return.png"
            style="
              width: 20px;
              height: 20px;
              margin: 10px 0 10px 8px;
              cursor: pointer;
            "
            @click="getCompanyInfo"
          />
          <span v-if="errMesg1" class="errorTip">{{ errMesg1 }}</span>
        </div>
      </el-form-item>
      <el-form-item
        label="企业名称"
        v-if="bannerIndex === 1 || bannerIndex === 4"
        prop="enterprise_name"
      >
        <span>{{ ruleForm.enterprise_name }}</span>
      </el-form-item>
      <el-form-item
        label="企业简称"
        v-if="bannerIndex === 1 || bannerIndex === 4"
        prop="enterprise_short_name"
      >
        <span>{{ ruleForm.enterprise_short_name }}</span>
      </el-form-item>

      <el-form-item
        label="logo"
        v-if="bannerIndex === 1 || bannerIndex === 4"
        prop="name"
      >
        <el-upload
          class="comp-cert-uploader"
          action="xx"
          :show-file-list="false"
          :accept="uploadAccept"
          :http-request="uploadLegal"
          :before-upload="beforeUpload"
        >
          <div v-if="ruleForm.logo_url" style="position: relative">
            <img
              :src="ruleForm.logo_url"
              style="width: 100px; height: 100px"
              class="avatar"
            />
            <i class="el-icon-close deleteImg" @click.stop="deleteLogo" />
          </div>
          <div v-else class="flex flex-center flex-v uploadView">
            <img
              src="../../../assets/images/add.png"
              style="width: 20px; height: 20px"
            />
            <span>立即上传</span>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="类目名称" v-if="bannerIndex === 2" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="类目图标" v-if="bannerIndex === 2" prop="name">
        <el-upload
          class="comp-cert-uploader"
          action="xx"
          :show-file-list="false"
          :accept="uploadAccept"
          :http-request="uploadLegal1"
          :before-upload="beforeUpload3"
        >
          <div v-if="ruleForm.icon_url" style="position: relative">
            <img
              :src="ruleForm.icon_url"
              style="width: 100px; height: 100px"
              class="avatar"
            />
            <i class="el-icon-close deleteImg" @click.stop="deleteIcon" />
          </div>
          <div v-else class="flex flex-center">
            <div
              class="flex flex-center flex-v uploadView"
              style="width: 100px; height: 100px"
            >
              <img
                src="../../../assets/images/add.png"
                style="width: 20px; height: 20px"
              />
              <span>立即上传</span>
            </div>
            <div class="sizeSuggest">
              <span>图片尺寸建议100px*100px;</span>
              <span>格式PNG</span>
            </div>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="类目图片" v-if="bannerIndex === 2" prop="name">
        <el-upload
          class="comp-cert-uploader"
          action="xx"
          :show-file-list="false"
          :accept="uploadAccept"
          :http-request="uploadLegal4"
          :before-upload="beforeUpload2"
        >
          <!-- <img v-if="ruleForm.imgUrl" :src="ruleForm.img_url" class="avatar" /> -->
          <div v-if="ruleForm.img_url" style="position: relative">
            <img
              :src="ruleForm.img_url"
              style="width: 100px; height: 100px"
              class="avatar"
            />
            <i class="el-icon-close deleteImg" @click.stop="deleteImage" />
          </div>
          <div v-else class="flex flex-center">
            <div
              class="flex flex-center flex-v uploadView"
              style="width: 100px; height: 100px"
            >
              <img
                src="../../../assets/images/add.png"
                style="width: 20px; height: 20px"
              />
              <span>立即上传</span>
            </div>
            <div class="sizeSuggest">
              <span>图片尺寸建议260px*452px;</span>
            </div>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="木材名称" v-if="bannerIndex === 3" prop="name">
        <el-input v-model="ruleForm.woodName"></el-input>
      </el-form-item>
      <el-form-item label="展会名称" v-if="bannerIndex === 5" prop="name">
        <!-- <div class="flex"> -->
        <el-input v-model="ruleForm.name"></el-input>
        <!-- <img
            src="../../../assets/images/return.png"
            style="width: 20px; height: 20px; margin: 10px 0 10px 8px"
          />
        </div> -->
      </el-form-item>
      <el-form-item label="排序" prop="order">
        <el-input v-model="ruleForm.order"></el-input>
      </el-form-item>
      <el-form-item label="举办时间" v-if="bannerIndex === 5" prop="name">
        <!-- <el-input v-model="ruleForm.hold_time"></el-input> -->
        <el-date-picker
          v-model="ruleForm.hold_time"
          type="datetime"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="举办地点" v-if="bannerIndex === 5" prop="hold_space">
        <el-input v-model="ruleForm.hold_space"></el-input>
      </el-form-item>
      <el-form-item label="联系我们" v-if="bannerIndex === 5" prop="name">
        <el-input v-model="ruleForm.contact_information"></el-input>
      </el-form-item>
      <span
        class="goods"
        v-if="bannerIndex === 1 || bannerIndex === 2 || bannerIndex === 3"
        >商品<span class="goodsNum"
          >（{{ ruleForm.commodity.length }}/10）</span
        ></span
      >
      <div>
        <div
          v-for="(item, index) in ruleForm.commodity"
          :key="index"
          class="goodsInfo flex flex-v flex-center"
          style="align-items: end"
        >
          <el-form-item
            label="商品ID"
            :prop="'commodity.' + index + '.commodityId'"
            :rules="[
              {
                required: true,
                message: '请输入商品ID',
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <div class="flex" style="position: relative">
              <el-input v-model="item.commodityId"></el-input>
              <img
                src="../../../assets/images/return.png"
                style="
                  width: 20px;
                  height: 20px;
                  margin: 10px 0 10px 8px;
                  cursor: pointer;
                "
                @click="getGoodsInfo(index)"
              />
              <span v-if="item.errMesg" class="errorTip">{{ item.errMesg }}</span>
            </div>
          </el-form-item>
          <el-form-item label="商品名称">
            <!-- <el-input v-model="item.companyName"></el-input> -->
            <!-- <span v-if="bannerIndex === 2">{{ item.company_name }}</span> -->
            <span>{{ item.companyName }}</span>
          </el-form-item>
          <!-- <el-form-item
            label="商品图片"
            v-if="bannerIndex === 2"
            :prop="'commodity.' + index + '.img_url'"
            :rules="[
              {
                required: true,
                message: '请输入商品图片',
                trigger: 'change',
              },
            ]"
          >
            <el-upload
              class="comp-cert-uploader"
              action="xx"
              :show-file-list="false"
              :accept="uploadAccept"
              :http-request="uploadLegal3"
              :before-upload="beforeUpload"
            >
              <div
                v-if="item.img_url"
                style="position: relative"
                @click="comIndex = index"
              >
                <img
                  :src="item.img_url"
                  style="width: 100px; height: 100px"
                  class="avatar"
                />
                <i
                  class="el-icon-close deleteImg"
                  @click.stop="deleteImg1(index)"
                />
              </div>
              <div v-else class="flex flex-center">
                <div
                  class="flex flex-center flex-v uploadView"
                  style="width: 100px; height: 100px"
                >
                  <img
                    src="../../../assets/images/add.png"
                    style="width: 20px; height: 20px"
                  />
                  <span @click="comIndex = index">立即上传</span>
                </div>
                <div class="sizeSuggest">
                  <span>图片尺寸建议800px*800px;</span>
                </div>
              </div>
            </el-upload>
          </el-form-item> -->
          <el-form-item
            label="商品图片"
            :prop="'commodity.' + index + '.imgUrl'"
            :rules="[
              {
                required: true,
                message: '请输入商品图片',
                trigger: 'change',
              },
            ]"
          >
            <el-upload
              class="comp-cert-uploader"
              action="xx"
              :show-file-list="false"
              :accept="uploadAccept"
              :http-request="uploadLegal3"
              :before-upload="beforeUpload5"
            >
              <!-- <img v-if="item.imgUrl" :src="item.imgUrl" class="avatar" style="width: 100px; height: 100px" /> -->
              <div
                v-if="item.imgUrl"
                style="position: relative"
                @click="comIndex = index"
              >
                <img
                  :src="item.imgUrl"
                  style="width: 100px; height: 100px"
                  class="avatar"
                />
                <i
                  class="el-icon-close deleteImg"
                  @click.stop="deleteImg(index)"
                />
              </div>
              <div v-else class="flex flex-center" @click="comIndex = index">
                <div
                  class="flex flex-center flex-v uploadView"
                  style="width: 100px; height: 100px"
                >
                  <img
                    src="../../../assets/images/add.png"
                    style="width: 20px; height: 20px"
                  />
                  <span>立即上传</span>
                </div>
                <div class="sizeSuggest">
                  <span>图片尺寸建议800px*800px;</span>
                </div>
              </div>
            </el-upload>
          </el-form-item>
          <img
            src="../../../assets/images/Sort.png"
            style="width: 20px; height: 20px"
            class="sort"
            @click="sortRow(index)"
          />
          <img
            src="../../../assets/images/delete.png"
            style="width: 20px; height: 20px"
            class="delete"
            @click="deleteRow(index)"
          />
        </div>
      </div>
      <el-button
        v-if="bannerIndex === 1 || bannerIndex === 2 || bannerIndex === 3"
        class="addGoodsBtn"
        @click="addGoods"
        >添加商品</el-button
      >
    </el-form>
    <div class="flex flex-center footer">
      <el-button @click="hide">取消</el-button>
      <el-button type="primary" @click="add">确定</el-button>
    </div>
  </div>
</template>
<script>
import { uploadFile, delFile } from "@/service/common";
import { IMG_URL_PRE, SERVER_USER_API, SERVER_ITEM_API } from "@/config";
import axios from "axios";
import {
  addWoodStyle,
  editWoodStyle,
  deleteGoods,
  addJoinStyle,
  editJoinStyle,
  addExhibitionStyle,
  editExhibitionStyle,
  editPopularStyle,
  addPopularStyle,
  editNewStyle,
  addNewStyle,
} from "@/service/operation";
export default {
  data() {
    return {
      rules: {
        order: [{ required: true, message: "排序不能为空", trigger: "blur" }],
        commodityId: [
          { required: true, message: "商品ID不能为空", trigger: "blur" },
        ],
        imgUrl: [
          { required: true, message: "商品图片不能为空", trigger: "blur" },
        ],
        companyName: [
          { required: true, message: "商品名称不能为空", trigger: "blur" },
        ],
      },
      uploadAccept: "image/jpeg,image/gif,image/png",
      ruleForm: {
        imageUrl: "",
        commodity: [],
      },
      comIndex: 0,
      errMesg: "",
      errMesg1: "",
    };
  },
  props: ["bannerIndex", "isAdd", "editObj", "popVisible"],
  components: {},
  methods: {
    getCompanyInfo() {
      this.errMesg1 = "";
      let data = { comp_id: this.ruleForm.mechanism_no };
      axios
        .post(`${SERVER_USER_API}/my-company/get-comp-baseinfo`, data)
        .then((res) => {
          console.log("res=>", res);
          if (res.data.errcode === 1) {
            this.errMesg1 = res.data.message;
            this.ruleForm.enterprise_short_name = "";
            this.ruleForm.enterprise_name = "";
          } else if (res.data.errcode === 0) {
            this.ruleForm.enterprise_name = res.data.result.name;
            this.ruleForm.enterprise_short_name = res.data.result.short_name;
          }
          this.ruleForm = Object.assign({}, this.ruleForm);
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    getGoodsInfo(index) {
      this.errMesg = "";
      let data = { spu_id: [this.ruleForm.commodity[index].commodityId] };
      axios
        .post(`${SERVER_ITEM_API}/factory/query-spu-sku`, data)
        .then((res) => {
          console.log("res=>", res);
          if (res.data.errcode === 1) {
            this.ruleForm.commodity[index].errMesg = res.data.message;
            this.ruleForm.commodity[index].commodityName = "";
            this.ruleForm.commodity[index].companyName = "";
          } else if (res.data.errcode === 0) {
            if (res.data.result.length) {
              this.ruleForm.commodity[index].commodityName =
                res.data.result[0].name;
              this.ruleForm.commodity[index].companyName =
                res.data.result[0].name;
            } else {
              this.ruleForm.commodity[index].errMesg = "作品不存在";
              this.ruleForm.commodity[index].commodityName = "";
              this.ruleForm.commodity[index].companyName = "";
            }
          }
          this.ruleForm = Object.assign({}, this.ruleForm);
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    uploadLegal(item) {
      uploadFile(item.file, "admin/portal/logo", {
        onUploadProgress: (event) => {
          item.file.percent = (event.loaded / event.total) * 100;
          item.onProgress(item.file);
        },
      })
        .then((url) => {
          this.ruleForm.logo_url = IMG_URL_PRE + url;
          this.ruleForm = Object.assign({}, this.ruleForm);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    uploadLegal4(item) {
      uploadFile(item.file, "admin/portal/logo", {
        onUploadProgress: (event) => {
          item.file.percent = (event.loaded / event.total) * 100;
          item.onProgress(item.file);
        },
      })
        .then((url) => {
          this.ruleForm.img_url = IMG_URL_PRE + url;
          this.ruleForm = Object.assign({}, this.ruleForm);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    uploadLegal1(item) {
      uploadFile(item.file, "admin/portal/logo", {
        onUploadProgress: (event) => {
          item.file.percent = (event.loaded / event.total) * 100;
          item.onProgress(item.file);
        },
      })
        .then((url) => {
          this.ruleForm.icon_url = IMG_URL_PRE + url;
          this.ruleForm = Object.assign({}, this.ruleForm);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    uploadLegal2(item) {
      uploadFile(item.file, "admin/portal/logo", {
        onUploadProgress: (event) => {
          item.file.percent = (event.loaded / event.total) * 100;
          item.onProgress(item.file);
        },
      })
        .then((url) => {
          this.ruleForm.img_url = IMG_URL_PRE + url;
          this.ruleForm = Object.assign({}, this.ruleForm);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    uploadLegal3(item) {
      uploadFile(item.file, "admin/portal/logo", {
        onUploadProgress: (event) => {
          item.file.percent = (event.loaded / event.total) * 100;
          item.onProgress(item.file);
        },
      })
        .then((url) => {
          // if (this.bannerIndex === 2) {
          //   this.ruleForm.commodity[this.comIndex].img_url =
          //     "http://cdn.file.nk.emergen.cn/dev/" + url;
          // } else {

          console.log(IMG_URL_PRE, url, this.comIndex);
          this.ruleForm.commodity[this.comIndex].imgUrl = IMG_URL_PRE + url;
          // }
          this.ruleForm = Object.assign({}, this.ruleForm);
          console.log(this.ruleForm, this.comIndex);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      // const isSize = new Promise(function (resolve, reject) {
      //   let width = 800;
      //   let height = 800;
      //   let _URL = window.URL || window.webkitURL;
      //   let img = new Image();
      //   img.onload = function () {
      //     let valid = img.width >= width && img.height >= height;
      //     valid ? resolve() : reject();
      //   };
      //   img.src = _URL.createObjectURL(file);
      // }).then(
      //   () => {
      //     return file;
      //   },
      //   () => {
      //     // this.$message.error("上传图片像素要大于800*800!");
      //     return;
      //   }
      // );
      return isLt2M;
    },
    beforeUpload5(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      const isSize = new Promise(function (resolve, reject) {
        let width = 800;
        let height = 800;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          let valid = img.width >= width && img.height >= height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("上传图片像素要大于800*800!");
          return Promise.reject();
        }
      );
      return isLt2M && isSize;
    },
    beforeUpload2(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      const isSize = new Promise(function (resolve, reject) {
        let width = 260;
        let height = 452;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          let valid = img.width >= width && img.height >= height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("上传图片像素要大于260*452!");
          return Promise.reject();
        }
      );
      return isLt2M && isSize;
    },
    beforeUpload3(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      const isSize = new Promise(function (resolve, reject) {
        let width = 100;
        let height = 100;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          let valid = img.width >= width && img.height >= height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("上传图片像素要大于100*100!");
          return Promise.reject();
        }
      );
      const isIMAGE = file.type === "image/png";
      if (!isIMAGE) {
        this.$message.error("上传文件只能是PNG格式!");
      }
      return isLt2M && isSize && isIMAGE;
    },
    addGoods() {
      if (this.ruleForm.commodity.length < 11) {
        this.ruleForm.commodity.push({});
      }
    },
    sortRow(index) {
      if (index != 0) {
        this.ruleForm.commodity[index] = this.ruleForm.commodity.splice(
          index - 1,
          1,
          this.ruleForm.commodity[index]
        )[0];
      } else {
        this.ruleForm.commodity.push(this.ruleForm.commodity.shift());
      }
    },
    deleteRow(index) {
      // this.ruleForm.commodity.splice(index, 1);
      deleteGoods({ commodityId: this.ruleForm.commodity[index].id })
        .then((res) => {})
        .catch((err) => {
          this.$message.error(err.message);
        });
      this.ruleForm.commodity.splice(index, 1);
      this.ruleForm = Object.assign({}, this.ruleForm);
    },
    hide() {
      this.$emit("hide");
    },
    add() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.bannerIndex === 3) {
            if (this.isAdd) {
              this.addWood();
            } else {
              this.editWood();
            }
          }
          if (this.bannerIndex === 4) {
            if (this.isAdd) {
              this.addJoin();
            } else {
              this.editJoin();
            }
          }
          if (this.bannerIndex === 2) {
            if (this.isAdd) {
              this.addPopur();
            } else {
              this.editPopur();
            }
          }
          if (this.bannerIndex === 5) {
            if (this.isAdd) {
              this.addExhibit();
            } else {
              this.editExhibit();
            }
          }
          if (this.bannerIndex === 1) {
            if (this.isAdd) {
              this.addNew();
            } else {
              this.editNew();
            }
          }
        }
      });
    },
    addNew() {
      if (this.ruleForm.commodity.length <= 0) {
        this.$message.error("至少添加一个商品");
        return;
      }
      const parma = {
        mechanism_no: this.ruleForm.mechanism_no,
        enterprise_name: this.ruleForm.enterprise_name,
        enterprise_short_name: this.ruleForm.enterprise_short_name,
        logo_url: this.ruleForm.logo_url,
        type: 1,
        commodity: this.ruleForm.commodity,
      };
      addNewStyle(parma)
        .then((res) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("提交成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    editNew() {
      if (this.ruleForm.commodity.length <= 0) {
        this.$message.error("至少添加一个商品");
        return;
      }
      const parma = {
        mechanism_no: this.ruleForm.mechanism_no,
        enterprise_name: this.ruleForm.enterprise_name,
        enterprise_short_name: this.ruleForm.enterprise_short_name,
        logo_url: this.ruleForm.logo_url,
        type: 1,
        commodity: this.ruleForm.commodity,
        id: this.ruleForm.id,
        order: this.ruleForm.order,
      };
      editNewStyle(parma)
        .then((res) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("编辑成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    addWood() {
      if (this.ruleForm.commodity.length <= 0) {
        this.$message.error("至少添加一个商品");
        return;
      }
      const parma = {
        woodName: this.ruleForm.woodName,
        order: this.ruleForm.order,
        type: 1,
        url: "",
        commodity: this.ruleForm.commodity,
      };
      addWoodStyle(parma)
        .then((res) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("提交成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    editWood() {
      if (this.ruleForm.commodity.length <= 0) {
        this.$message.error("至少添加一个商品");
        return;
      }
      const parma = {
        woodName: this.ruleForm.woodName,
        order: this.ruleForm.order,
        type: 1,
        url: this.ruleForm.url,
        commodity: this.ruleForm.commodity,
        woodStyleId: this.ruleForm.woodStyleId,
      };
      editWoodStyle(parma)
        .then((res) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("编辑成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    addJoin() {
      const parma = {
        mechanism_no: this.ruleForm.mechanism_no,
        enterprise_name: this.ruleForm.enterprise_name,
        order: this.ruleForm.order,
        enterprise_short_name: this.ruleForm.enterprise_short_name,
        logo_url: this.ruleForm.logo_url,
      };
      addJoinStyle(parma)
        .then((res) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("提交成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    editJoin() {
      const parma = {
        mechanism_no: this.ruleForm.mechanism_no,
        enterprise_name: this.ruleForm.enterprise_name,
        enterprise_short_name: this.ruleForm.enterprise_short_name,
        order: this.ruleForm.order,
        type: 1,
        logo_url: this.ruleForm.logo_url,
        id: this.ruleForm.id,
      };
      editJoinStyle(parma)
        .then((res) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("编辑成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    addPopur() {
      if (this.ruleForm.commodity.length <= 0) {
        this.$message.error("至少添加一个商品");
        return;
      }
      const parma = {
        name: this.ruleForm.name,
        icon_url: this.ruleForm.icon_url,
        order: this.ruleForm.order,
        img_url: this.ruleForm.img_url,
        commodity: this.ruleForm.commodity,
      };
      addPopularStyle(parma)
        .then((res) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("提交成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    editPopur() {
      if (this.ruleForm.commodity.length <= 0) {
        this.$message.error("至少添加一个商品");
        return;
      }
      const parma = {
        popularId: this.ruleForm.popularId,
        name: this.ruleForm.name,
        icon_url: this.ruleForm.icon_url,
        order: this.ruleForm.order,
        img_url: this.ruleForm.img_url,
        commodity: this.ruleForm.commodity,
      };
      console.log(parma);
      editPopularStyle(parma)
        .then((res) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("编辑成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    addExhibit() {
      const parma = {
        name: this.ruleForm.name,
        hold_space: this.ruleForm.hold_space,
        contact_information: this.ruleForm.contact_information,
        hold_time: this.ruleForm.hold_time,
        order: this.ruleForm.order,
      };
      addExhibitionStyle(parma)
        .then((res) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("提交成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    editExhibit() {
      const parma = {
        id: this.ruleForm.id,
        name: this.ruleForm.name,
        hold_space: this.ruleForm.hold_space,
        contact_information: this.ruleForm.contact_information,
        hold_time: this.ruleForm.hold_time,
        order: this.ruleForm.order,
      };

      editExhibitionStyle(parma)
        .then((res) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("编辑成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    deleteImg(index) {
      this.ruleForm.commodity[index].imageUrl = "";
      this.ruleForm.commodity[index].imgUrl = "";
      this.ruleForm = Object.assign({}, this.ruleForm);
    },
    deleteImg1(index) {
      if (this.bannerIndex === 2) {
        this.ruleForm.commodity[index].img_url = "";
      }

      this.ruleForm = Object.assign({}, this.ruleForm);
    },
    deleteLogo() {
      this.ruleForm.logo_url = "";
      this.ruleForm = Object.assign({}, this.ruleForm);
    },
    deleteIcon() {
      this.ruleForm.icon_url = "";
      this.ruleForm = Object.assign({}, this.ruleForm);
    },
    deleteImage() {
      this.ruleForm.img_url = "";
      this.ruleForm = Object.assign({}, this.ruleForm);
    },
  },
  watch: {
    popVisible: {
      handler(newVal) {
        if (newVal) {
          // if(!this.isAdd){

          this.ruleForm = Object.assign({}, this.editObj);
          console.log(this.editObj, this.ruleForm);
          // }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped>
@import "../style.css";
</style>
<style>
.addBanner .el-input__inner {
  width: 260px;
}
.addBanner .el-input {
  width: 260px;
}
.addBanner .el-form-item {
  margin-bottom: 10px;
}
.errorTip {
  height: 16px;
  font-size: 12px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #f66f6a;
  line-height: 17px;
  position: absolute;
  bottom: -17px;
  left: 0px;
}
</style>